* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0;
}

body,
ul,
li {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

Button link {
  font-family: 'Mazzard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
}

body,
#root,
.app {
  margin: 0 auto;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;


  background: linear-gradient(180deg, #081619de, #0f444b, #081619de);
}

@media screen and (max-width: 768px) {
  #root {
    overflow-y: scroll;
  }
}

@media screen and (max-height: 420px) {
  #root {
    overflow-y: scroll;
  }
}

@font-face {
  font-family: 'Mazzard';
  src: local('Mazzard M Medium'), local('Mazzard-M-Medium'), url(../public/fonts/Mazzard/MazzardM-Medium.woff2) format('woff2'),
    url(../public/fonts/Mazzard/MazzardM-Medium.woff) format('woff'), url(../public/fonts/Mazzard/MazzardM-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mazzard';
  src: local('Mazzard M Regular'), local('Mazzard-M-Regular'), url(../public/fonts/Mazzard/MazzardM-Regular.woff2) format('woff2'),
    url(../public/fonts/Mazzard/MazzardM-Regular.woff) format('woff'), url(../public/fonts/Mazzard/MazzardM-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mazzard';
  src: local('Mazzard M SemiBold'), local('Mazzard-M-SemiBold'), url(../public/fonts/Mazzard/MazzardM-SemiBold.woff2) format('woff2'),
    url(../public/fonts/Mazzard/MazzardM-SemiBold.woff) format('woff'), url(../public/fonts/Mazzard/MazzardM-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'), url(../public/fonts/Inter/Inter-Regular.woff2) format('woff2'),
    url(../public/fonts/Inter/Inter-Regular.woff) format('woff'), url(../public/fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}